import { ensureHttps } from './url';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';

export const safeFetch = (url: string, options?: RequestInit) => {
  // Add base URL if the URL is relative
  const fullUrl = url.startsWith('http') ? url : `${API_URL}${url}`;
  
  // Clean up any double slashes (except after protocol)
  const cleanUrl = fullUrl.replace(/([^:]\/)\/+/g, '$1');
  
  // Remove any trailing slashes before query params
  const finalUrl = cleanUrl.replace(/\/\?/, '?');
  
  console.log('Original URL:', url);
  console.log('Final URL:', finalUrl);
  
  // Add authorization header if token exists
  const token = localStorage.getItem('token');
  if (token) {
    options = {
      ...options,
      headers: {
        ...options?.headers,
        'Authorization': `Bearer ${token}`,
      },
    };
  }
  
  return fetch(finalUrl, options);
};
