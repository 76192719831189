import { Bookmark } from '../types/bookmark';
import { safeFetch } from '../utils/api';

interface BookmarkResponse {
  bookmarks: {
    [date: string]: Array<{
      id: string;
      url: string;
      title: string;
      bookmarked_date: string;
      date_last_used: string | null;
      labels: string[];
    }>;
  };
  total: number;
  page: number;
  page_size: number;
  total_pages: number;
}

export const fetchBookmarks = async (): Promise<Bookmark[]> => {
  const url = `/api/bookmarks`;
  console.log('Fetching bookmarks from:', url);
  const response = await safeFetch(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    const error = await response.text();
    throw new Error(error || 'Failed to fetch bookmarks');
  }

  return response.json();
};

export const getBookmarks = async (page: number = 1, pageSize: number = 20): Promise<BookmarkResponse> => {
  const response = await safeFetch(`/api/bookmarks?page=${page}&page_size=${pageSize}`);
  if (!response.ok) {
    throw new Error('Failed to fetch bookmarks');
  }
  return response.json();
};

export const getBookmarkLabels = async (): Promise<string[]> => {
  console.log('Fetching bookmark labels...');
  const response = await safeFetch('/api/bookmarks/labels');
  console.log('Labels response:', response);
  if (!response.ok) {
    throw new Error('Failed to fetch bookmark labels');
  }
  const data = await response.json();
  console.log('Labels data:', data);
  return data;
};

export const updateBookmarkLabels = async (bookmarkId: string, labels: string[]): Promise<void> => {
  const response = await safeFetch(`/api/bookmarks/${bookmarkId}/labels`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ labels }),
  });
  
  if (!response.ok) {
    throw new Error('Failed to update bookmark labels');
  }
};
