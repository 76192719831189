import { safeFetch } from '../utils/api';

export const login = async (email: string, password: string) => {
  const formData = new URLSearchParams();
  formData.append('username', email);  // OAuth2 expects 'username' field
  formData.append('password', password);
  formData.append('grant_type', 'password');

  const url = `/api/auth/token`;
  console.log('Logging in at:', url);
  const response = await safeFetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formData.toString()
  });

  if (!response.ok) {
    const error = await response.text();
    throw new Error(error || 'Failed to login');
  }

  const data = await response.json();
  localStorage.setItem('token', data.access_token);
  return data;
};

export const register = async (email: string, password: string) => {
  const url = `/api/auth/register`;
  console.log('Registering at:', url);
  const response = await safeFetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, password })
  });

  if (!response.ok) {
    const error = await response.text();
    throw new Error(error || 'Failed to register');
  }

  const data = await response.json();
  localStorage.setItem('token', data.access_token);
  return data;
};
