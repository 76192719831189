import React, { useEffect, useState, useCallback, useRef } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Pagination,
  CircularProgress,
} from '@mui/material';
import { getBookmarks, updateBookmarkLabels } from '../api/bookmarks';
import { useAuth } from '../contexts/AuthContext';
import { parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

// Label colors for different label types
const LABEL_COLORS = {
  vc: '#FF6B6B',      // Coral Red
  arc: '#4ECDC4',     // Turquoise
  actify: '#45B7D1',  // Sky Blue
  ai: '#96CEB4',      // Sage Green
  'hands-on': '#FFEEAD', // Light Yellow
  deepdive: '#9B59B6'  // Purple
};

interface Bookmark {
  id: string;
  url: string;
  title: string;
  bookmarked_date: string;
  date_last_used: string | null;
  labels: string[] | null;  // Allow null to match backend response
}

interface GroupedBookmarks {
  [date: string]: Bookmark[];
}

const BookmarkList: React.FC = () => {
  const { token } = useAuth();
  const [bookmarks, setBookmarks] = useState<GroupedBookmarks>({});
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    let isActive = true;

    const fetchBookmarks = async () => {
      if (!token) return;
      
      try {
        setIsLoading(true);
        const data = await getBookmarks(page);
        
        if (!isActive) return;

        // Convert UTC dates to local time and regroup
        const localGroupedBookmarks: GroupedBookmarks = {};
        Object.entries(data.bookmarks).forEach(([date, bookmarks]) => {
          bookmarks.forEach(bookmark => {
            // Get local date using formatInTimeZone
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const dateKey = formatInTimeZone(parseISO(bookmark.bookmarked_date), timezone, 'yyyy-MM-dd');
            
            if (!localGroupedBookmarks[dateKey]) {
              localGroupedBookmarks[dateKey] = [];
            }
            localGroupedBookmarks[dateKey].push(bookmark);
          });
        });

        // Sort dates in reverse chronological order
        const sortedGroupedBookmarks = Object.fromEntries(
          Object.entries(localGroupedBookmarks).sort((a, b) => b[0].localeCompare(a[0]))
        );

        if (isActive) {
          setBookmarks(sortedGroupedBookmarks);
          setTotalPages(data.total_pages);
        }
      } catch (error) {
        console.error('Error fetching bookmarks:', error);
      } finally {
        if (isActive) {
          setIsLoading(false);
        }
      }
    };

    fetchBookmarks();

    return () => {
      isActive = false;
    };
  }, [token, page]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    if (value !== page) {
      setPage(value);
    }
  };

  const handleLabelToggle = useCallback(async (bookmark: Bookmark, label: string) => {
    if (isLoading) return;

    const currentLabels = bookmark.labels ?? [];
    const newLabels = currentLabels.includes(label)
      ? currentLabels.filter(l => l !== label)
      : [...currentLabels, label];

    try {
      setIsLoading(true);
      await updateBookmarkLabels(bookmark.id, newLabels);
      
      // Update local state immediately after successful API call
      setBookmarks(prevBookmarks => {
        const newBookmarks = { ...prevBookmarks };
        // Find the date key that contains this bookmark
        Object.keys(newBookmarks).forEach(date => {
          const bookmarkIndex = newBookmarks[date].findIndex(b => b.id === bookmark.id);
          if (bookmarkIndex !== -1) {
            // Create a new bookmark object with updated labels
            const updatedBookmark = {
              ...newBookmarks[date][bookmarkIndex],
              labels: newLabels
            };
            // Create a new array with the updated bookmark
            newBookmarks[date] = [
              ...newBookmarks[date].slice(0, bookmarkIndex),
              updatedBookmark,
              ...newBookmarks[date].slice(bookmarkIndex + 1)
            ];
          }
        });
        return newBookmarks;
      });
    } catch (error) {
      console.error('Error updating labels:', error);
    } finally {
      setIsLoading(false);
    }
  }, [isLoading]);

  const formatDate = useCallback((dateString: string) => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return formatInTimeZone(parseISO(dateString), timezone, 'MMMM d, yyyy');
  }, []);

  const LabelTiles: React.FC<{ bookmark: Bookmark }> = ({ bookmark }) => {
    // Default to empty array if labels is null/undefined
    const currentLabels = bookmark.labels ?? [];
    
    return (
      <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap', mt: 1 }}>
        {/* Always show all predefined labels */}
        {Object.keys(LABEL_COLORS).map((label) => {
          const isSelected = currentLabels.includes(label);
          return (
            <Box
              key={label}
              onClick={() => handleLabelToggle(bookmark, label)}
              sx={{
                cursor: isLoading ? 'wait' : 'pointer',
                backgroundColor: isSelected ? LABEL_COLORS[label as keyof typeof LABEL_COLORS] : '#e0e0e0',
                color: isSelected ? '#fff' : '#757575',
                padding: '4px 8px',
                borderRadius: '4px',
                fontSize: '0.75rem',
                fontWeight: 500,
                transition: 'all 0.2s ease',
                opacity: isLoading ? 0.7 : 1,
                '&:hover': {
                  opacity: isLoading ? 0.7 : 0.8,
                  transform: isLoading ? 'none' : 'translateY(-1px)'
                }
              }}
            >
              {label}
            </Box>
          );
        })}
      </Box>
    );
  };

  return (
    <Box sx={{ maxWidth: 800, margin: '0 auto', p: 2 }}>
      {Object.entries(bookmarks).map(([date, dateBookmarks]) => (
        <Box key={date} sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ mb: 2, color: 'text.secondary' }}>
            {formatDate(date)}
          </Typography>
          {dateBookmarks.map((bookmark) => (
            <Card key={bookmark.id} sx={{ mb: 2 }}>
              <CardContent>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h6" component="a" href={bookmark.url} target="_blank" rel="noopener noreferrer"
                    sx={{ textDecoration: 'none', color: 'primary.main', '&:hover': { textDecoration: 'underline' } }}>
                    {bookmark.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                    {bookmark.url}
                  </Typography>
                  <LabelTiles bookmark={bookmark} />
                </Box>
              </CardContent>
            </Card>
          ))}
        </Box>
      ))}

      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          color="primary"
          disabled={isLoading}
        />
      </Box>
    </Box>
  );
};

export default BookmarkList;
